.printonly{display:none;}

.underline{text-decoration:underline;}

.cursorHand{cursor:pointer;}

.clear{clear:both;}
.center{margin:0 auto; text-align: center;}
.alignR{text-align: right;}
.alignC{text-align: center;}
.floatL{float:left;}
.floatR{float:right;}
.floatHalf{float:left; width:50%;}
.floatThird{float:left; width:33%;}
.floatQtr{float: left; width: 25%;}
.nomargin{margin:0;}
.nowrap{white-space: nowrap;}
.marginL{margin-left: 20px;}
.marginL5{margin-left:5px;}
.marginL10{margin-left:10px;}
.marginR{margin-right: 20px;}
.marginR5{margin-right: 5px;}
.marginR10{margin-right: 10px;}
.marginT{margin-top: 20px;}
.marginT1{margin-top: 1px;}
.marginT2{margin-top: 2px;}
.marginT5{margin-top: 5px;}
.marginT6{margin-top: 6px;}
.marginT8{margin-top: 8px;}
.marginT10{margin-top: 10px;}
.marginB5{margin-bottom: 5px;}
.marginB10{margin-bottom: 10px;}
.padding5{padding: 5px;}
.padding10{padding: 10px;}
.paddingL{padding-left: 20px;}
.paddingR{padding-right: 20px;}
.paddingT2{padding-top:2px;}
.paddingT5{padding-top: 5px;}
.greyBg{background: #f8f8f8;}
.greyBorder{border: 1px solid #cbcbcb;}
.greyBox, .greyBox5{border: 1px solid #cbcbcb; background: #f8f8f8; padding: 10px;}
.greyBox5{padding: 5px;}
.greyBorderB{border-bottom: 1px solid #cbcbcb;}
.borderL{border-left: 1px solid #eee;}
.borderR{border-right: 1px solid #eee;}
.displayNone{display:none;}
.bold{font-weight:bold;}
.underline{text-decoration: underline;}
.strike{text-decoration: line-through;}
.size9{font-size: 9px;}
.size10{font-size:10px;}
.size11{font-size:11px;}
.hand{cursor: pointer;}

.width250{width: 250px;}
.width75{width: 75px;}
.width50{width: 50px;}
.width25px, .width25{width:25px;}
.width10{width:10px;}
.width100P{width:100%;}
.width90P{width:90%;}
.width75P{width:75%;}
.width50P{width:50%;}
.width60P{width:60%;}

.height75{height: 75px;}
.height50{height: 50px;}

.rowBg{}
.rowBgAlt{}

.imageNone{display:inline-block; border:1px solid #eee; color: #eee; text-align: center;padding:0; margin:0;}
.imageNone p{padding: 5px;margin:0; font-size:10px; font-weight:bold; text-decoration:none;}
.imageNone.height75 p{padding-top: 30px;}
.imageNone.height50 p{padding-top: 20px;}
.imageNoneW35H31 p{padding-top: 6px !important; font-size:7px;}

h1, h2, h3, h4{padding:0; margin:5px 0 10px;}

.sup{font-size:9px !important;}
.red{color: red;}
.delete{background: red;}

/******************************/
/*Layouts*/
.layoutcssOnly{margin: 10px !important;}

/******************************/
/*Forms*/
.formRow{margin-bottom:10px;}
.formLeft{width:48%; float:left;}
.formRight{width:48%; float:right;}
.inputWidth90P{width:90%;}
.inputWidth50P{width:50%;}

.formSection{margin: 20px 0 35px; padding:10px 0 0; border:0;}
.formSection legend{font-weight:bold;}

/******************************/
/*Account - Order*/
.order_no, .order_amount, .order_date{float: left;}
.order_no{width: 80%;}
.order_amount, .order_date{width:20%; text-align:right;}
.order_date{float:right;}

.orders_shipment{padding:5px;}
.rowBg .orders_shipment{background: #fbfbfb;}
.rowBgAlt .orders_shipment{background: #f3f3f3;}
.rowActive .orders_shipment{background: #dff7c4;}
.orders_shipment .status, .orders_shipment .ship_method, .orders_shipment .tracking_no{float:  left; width:33%;}
.orders_products{margin:5px 0 0;}
.orders_package{float: left; width: 20%;}
.orders_package .details{padding:5px;}
.orders_package .title{text-transform: uppercase; font-weight:bold;}
.orders_package .shipmethod{margin-top: 5px;}
.orders_product{float:left; width:80%;}
.orders_product ul{margin:0; padding:5px; list-style:none;}
.orders_product ul li{padding: 0 0 2px;}
.rowBg .orders_package, .rowBg .orders_product{background: #f6f6f6;}
.rowBgAlt .orders_package, .rowBgAlt .orders_product{background: #f1f1f1;}
.rowActive .orders_package, .rowActive .orders_product{background: #d4ecb9}

.header .cart_packages, .header .cart_shipments{padding:9px 0 0; font-weight:bold;}
.cart_shipments{float:left; width: 24%; position:relative;}
.cart_packages{float: right; width:71%; padding: 0 5px 0 5px; margin:0 5px 0 !important;}
.package_info{border-bottom: 1px solid #fff; padding: 3px 5px; margin: 4px 0 0px 0px; font-weight: bold; text-transform: uppercase; font-size:11px;}
.rowBg .package_info{}
.rowBgAlt .package_info{}
#order_cart{font-size:11px;}
#order_cart .cart_packages{margin: 4px 0;}
.cart_products_row{margin: 4px 0 4px;}
.cart_products{float:left; position:relative; width: 50%;}

.cart_prices{float: left; width: 20%; text-align:right;}
.cart_qty{float: left; width: 10%; text-align:right; min-height:8px;}
.cart_totals{float:left; width:20%; text-align:right;}
.rowBg .cart_packages{}
.rowBgAlt .cart_packages{}
/*.rowBg .cart_shipment_shipping{background: #f4f4f4;}
.rowBgAlt .cart_shipment_shipping{background: #fbfbfb;}*/
.cart_shipment_ship{position: relative;float:right; width: 35%; margin-top:3px;}
.cart_shipment_shipping{position: relative; top: 0; right: 5px; width:100%;}
.cart_shipment_shipping div{padding:5px 5px 5px 5px; text-align:right; border-right:0;}
.cart_shipments .buttons{padding:5px 5px 5px 5px; font-size:11px;}
.cart_shipments .title{font-weight:bold; text-transform:uppercase;}
.cart_shipments .status{margin-top: 5px;}
.cart_shipments .shipping{margin-top: 5px;}
.shipment_info{position: relative; top: 0; left: 5px; width:100%;}
.shipment_outline{}
.cart_products_row.discount{margin:5px 0; color:red;}

#order_cart_totals{float: right; width: 33%;}
.totals_row{padding:4px 8px;}
#order_cart_totals .label{float: left; width:50%; text-align:right;}
#order_cart_totals .total{float:right; text-align:right; padding-right: 3px;}
#show_total{font-weight:bold; font-size:14px;}

.tracking_label{position: relative; padding:5px;}
.tracking_label.active{padding:4px; background:#fff; border:1px solid #c7d9e2;}
.tracking_box{display:none; position: absolute; z-index: 2; left: 50px; background:#fff; border:1px solid #c7d9e2; padding:4px;}

/******************************/
/*Messages*/
.message_error, .message_success, .ajax_error{
	text-align: left;
	font-family: Helvetica, Arial;
	font-size: 11px;
	margin: 5px 0;
	padding: 2px;
	color: #000;
    min-height: 32px;
}
.message_error .small_text, .message_success .small_text, 
.ajax_error .small_text{float: left; font-size: 11px;}

.show_hide{
	float: right;
	font-weight: bold;
	padding: 2px 4px;
	border: 1px solid #035d8c;
	color: #035d8c;
	background: #fff;
	cursor: pointer;
	position: relative;
	font-size: 9px;
	text-transform: uppercase;
	margin-bottom: 2px; 
}

.message_error .show_hide{border-color: red; color: red;} 

.message_error ul, .message_success ul, .ajax_error ul{
	list-style-type: square;
	margin: 7px 0 3px 65px;
}

.message_error, .ajax_error{
	border: 1px solid red;
	background: url("../images/icon_error.gif") 7px 2px no-repeat #fffeed;
}

.message_success{
	border: 1px solid green;
	background: url("../images/icon_success.gif") 7px 2px no-repeat #fffeed;
}

.ajax_load{text-transform: uppercase; font-size: 10px; font-weight: bold; color: #505b60; background: #ddeaf1; border: 1px solid #cbcbcb; text-align: center; width: auto; padding: 5px;}
.ajax_loader{margin: 0 auto; background: url('../images/ajax-loader.gif') center left no-repeat; padding-left: 25px; text-align: left;}
.ajax_med_load{height: 35px; background: url('../images/ajax_med_loader.gif') center center #fff no-repeat; text-transform: uppercase; font-size: 11px; font-weight: bold; color: #666; text-align: center; width: auto;}
.ajax_med_loader{padding-top: 11px;}
.ajax_big_load{height: 66px; background: url('../images/ajax_big_loader.gif') center center no-repeat; text-transform: uppercase; font-size: 11px; font-weight: bold; color: #666; text-align: center; width: auto;}
.ajax_big_loader{padding-top: 25px;}

/******************************/
/*Check Out*/
.cStep1 a{margin-left:0;}
.cStep5 a{margin-right:0;}

.check_out{font-size:18px !important;}

.checkout_continue, .checkout_complete{margin-top: 20px;}
.checkout_complete p{font-size: 12px; font-weight:bold; padding-top: 6px;}
.processing_button{background-image: url('../images/ajax-loader.gif') !important; 
background-position: 5px center !important; background-repeat: no-repeat !important; 
cursor:default !important; padding-left: 30px !important;}
.processing_order{background: url('../images/ajax-loader.gif') 9px center no-repeat !important; cursor: default; padding-left: 32px;}
#checkout_left{float:left; width: 60%;}
#checkout_sidebar{float:right; width:30%;}

.checkmark{background: url("../images/checkmark_bg.png") 0 0 no-repeat; width:22px; height:22px; position: relative;
cursor:pointer;}
.checkmark div{width:27px; height:24px; background: url("../images/checkmark_selected.png") 0 0 no-repeat;
position: absolute; left:-1px; bottom:-1px; display:none;}

.checkout_header, .account_type_header{margin: 20px 0 10px; padding-left:0; padding-right:0;}
.account_type_header{margin:20px 0 4px; padding:0;}
.account_type_row{margin:0 0 5px 0; padding: 5px;}
.account_type_row.active{font-weight: bold;}
.account_type_right{float:left; margin: 2px 0 0 5px;}
.account_type_desc{padding: 5px 0; margin: 3px 0 3px; font-weight: normal;}
#account_type_2 .forgot_password{font-weight:normal;}

#checkout_shipping{}

#checkout_creditcard_info{margin-bottom: 25px;}
#checkout_receipt{}

.account_select_shiping, .account_select_billing{margin-top: 10px;}
.shiping_display, .billing_display{padding:5px;}
.shiping_display h4, .billing_display h4{margin: 0 0 5px; text-transform:uppercase;}

.checkout_address_tabs{position:relative; height: 35px; border-bottom: 1px solid #eee;}
.address_tab{float: left; width: 50%;}
.tab{position: relative; z-index:2; height: 34px; margin:0 auto; width: 90%; border:1px solid #eee; border-bottom:0; background: #f8f8f8;}
.tab.active{height: 35px; background: #fff; font-weight:bold;}
.tab p{padding: 5px 8px 5px 6px; margin:0; color: #666;}
.billing_address, .shiping_address{padding: 5px;}

.account_add_billing, .account_add_shiping{padding:5px 0;}

.cvvField{width: 40px;}

.checkout_shipment{margin: 4px 0 4px; padding: 10px;}
.checkout_shipment h4{margin: 0 0 3px; padding:0;}
.checkout_shipment .shipment_includes, .checkout_shipment .shipment_selections{margin: 3px 0;}
.shipment_includes ul{margin: 0; padding: 0;}
.shipment_includes li{padding: 0 5px; float:left;}
.shipment_selections{}

#checkout_creditcard_info .cc_left, #checkout_creditcard_info .cc_right{float:left; width: 50%;}
#checkout_creditcard_info h3{float:left; text-align:center; width: 6%;}

#availableCims{}
#availableCims .header{margin-bottom: 1px; font-weight:bold; text-transform:uppercase; font-size:11px; background: #F07C33; color: #fff;}
#availableCims .header, #availableCims .cimSelection{padding:5px;}
#availableCims .select, #availableCims .name, #availableCims .cc_number, #availableCims .cc_exp, #availableCims .cc_cvv{float:left; width: 30%;}
#availableCims .name{width:30%;}
#availableCims .cc_number{width: 40%;}
#availableCims .cc_cvv{width: 15%;}
#availableCims .select{width: 17px; min-height:15px; margin: 0 10px 0 5px; position: relative;}
#availableCims .select .box{position: absolute; z-index:1; margin: 0 auto; background: #fff; width:13px; height: 13px; border:1px solid #cbcbcb;}
#availableCims .select img{position: absolute; z-index:2; display:none;}
#availableCims .select input{margin: 0; padding:0; display:none;}
#availableCims .cimSelection{cursor:pointer; border-bottom: 1px solid #efefef; padding: 8px 5px 20px;}
.cimSelection .cc_cvv input{display:none; margin:0; padding: 1px;}
#availableCims .cimSelection:hover{background: #cdcdcd;}
#availableCims .cimSelectionActive{background: #cdcdcd;}
#availableCims .cimSelectionActive .select img{display:block;}
.cimSelectionActive .cc_cvv input{display:inline-block;}
.cimEdit{display:block; float:right; margin: -20px 15% 0 37px; position: relative; z-index:2; font-size:10px; text-decoration: underline;}
#checkoutEditCim h2{color: #FD8E0D; font-size:14px;}

.tabsContainer{position: relative;}
.tabsContent, .checkout_tab{border: 1px solid #eee;}
.tabsContent{position: relative; padding:10px 30px; z-index:3; margin-left: 160px; background: #666; min-height: 173px;}
.checkout_tab{display:inline-block; position: absolute; z-index:2; left:0; width: 160px; background: #f9f8f8; border-right:0;}
.checkout_tab p{margin:0; padding: 20px 10px; font-weight:bold; color: #929191; font-size:13px;}
.checkout_tab:hover{background: #666;}
.checkout_tab:hover p{color: #fff;}
.tabActive{z-index:4; background: #666; cursor: default;}
.tabActive p{color: #F07C33 !important;}
.tabContent{display:none;}
.tabContentActive{display:block;}
#tab_useCims{top: 20px;}
#tab_newCc{top: 100px;}

#useCimsLeft, #newCcLeft{float:left; width: 60%;}
#useCimsRight, #newCcRight{float:right; width: 35%;}
#newCcLeft{margin-top:10px;}

#checkoutAffiliateCode{margin-top: 30px;}
#checkoutAffiliateCode h2{margin-bottom: 4px;}

#add_shipping_address_container, #add_billing_address_container{position: fixed; left:0; top:0; width: 100%; z-index:1000;}
#add_shipping_address_popup, #add_billing_address_popup{background: #fff; padding: 15px; margin:100px auto 0; width: 400px;
box-shadow: 0 3px 5px rgba(0,0,0,0.5); -moz-box-shadow: 0 3px 5px rgba(0,0,0,0.5); -webkit-box-shadow: 0 3px 5px rgba(0,0,0,0.5);}

#add_shipping_address_popup h2, #add_billing_address_popup h2{margin-top:0;}

/******************************/
/*Search Results*/
#cartSearchResults{}
#cartSearchResults .products{overflow:hidden;}


/******************************/
/*Contact Us*/
#contactLeft, #contactRight{float:left; width: 48%;}
#contactRight{float:right;}
#contactLeft h3{margin: 0 0 10px;}

/******************************/
/*Page*/
.staticPage p{text-align: justify; line-height: 160%;}

/******************************/
/*Breadcrumbs*/
#cartBreadcrumbs{margin: 0 0 10px;}
#cartBreadcrumbs ul{margin: 0; padding:0; list-style:none;}
#cartBreadcrumbs li{float:left; padding: 0 5px 0 0;}
#cartBreadcrumbs a{display:block;}

/******************************/
/*Categories*/
#currentCategoryTitle{margin: 0 0 10px; padding:0; float:left;}
#subcategoryTitle{margin: 0 0 5px; font-size:12px; display:none;}

#cartCategories{font-size:12px; width:auto;}
#cartCategories h3{margin:0;}
#cartCategories ul{list-style:none; margin:0; padding:0;}
#cartCategories li{position: relative; padding:0 0 0 12px; height:18px; margin: 3px 0;}
#cartCategories .catArrow{position: absolute; left: 0; top:0; background: url("../images/catArrow.gif") 5px center no-repeat; height: 18px; width:15px;}
#cartCategories li a{height:18px; font-weight:bold; display: block;}
#cartCategories li a:hover, #cartCategories .activeCat{}
#cartCategories li a:hover, #cartCategories .activeCat a{}
#cartCategories li a p{padding: 2px 8px 0; margin:0;}

#subcategories .category{cursor:pointer; float: left; width: 25%; text-align: center; margin-bottom: 15px;}
#subcategories .category .image{width:150px; height: 100px; margin:0 auto; overflow:hidden;}
#subcategories .category .title{ text-align: center; display:block; font-weight:normal; font-size:12px; margin-top:1px;}
#subcategories .category:hover .title{}
#subcategories .category .imageNone{margin:0 auto; text-align:center;}

/******************************/
/*Product Thumbnails*/
#categoryProducts{clear:both; padding: 0px 0 0 0;}

/*.cellBgAlt{background: #faf9f9;}*/

.rating{width:50px; height:13px; position:relative; margin:3px auto 8px; overflow:hidden;}
.rating .empty{background: url("../images/stars.png") right -10px no-repeat; position:absolute; height:10px; right:0;}
.rating .full{background: url("../images/stars.png") left 0px no-repeat; position:absolute; height:10px; left:0;}

.productListRow .summary p{margin: 0 0 5px; font-size:11px;}
.productListRow .summary{margin: 5px 0 0;}

#featuredProducts{}
#featuredProducts .productsContainer{padding: 10px 0 0;}
#featuredProducts h3{margin:4px 0 3px;}
.productFeatured{width:33%; border:0; background:none; margin: 0 0 10px;}
.productFeatured{padding-top:5px !important;}
.productFeatured .productInner{margin:0 0 0 1px; padding: 0 5px 5px;}
.productFeatured .image{float:left; width:100px; height: 130px; overflow:hidden;}
.productFeatured .info{float:left; width: 140px; margin-left:8px;}
.productFeatured .title{font-size:14px; margin-top: 4px; height:38px;}
.productFeatured .brand{height: 24px;}
.productFeatured .title, .productFeatured .price, .productFeatured .price_reg, .productFeatured .price_sale{text-align:left; font-size:12px;}
.productFeatured .rating{margin: 2px 0 0;}
.featuredQtr{width:25%;}
.featuredQtr a{display:block; padding: 0px 10px;}
.featuredQtr .title{font-weight:bold; height: 26px; margin-top:5px;}
.featuredQtr .rating{height: 14px;}
.featuredQtr .info, .featuredQtr .image{width:auto; float:none; clear:both; margin-left:0;}
.featuredQtr .pricing{padding-top: 5px; font-size:11px; font-weight:bold;}
.featuredQtr .price{display:inline; font-size:11px;}
.featuredQtr .description p{margin:0; padding:0; display:inline; font-size:11px;}

#cartRelatedProducts .productThumb, #cartSideRecentlyViewed .productThumb{float:none; width: auto; border:0; padding:0; height:auto; margin: 0 0 10px;}
#cartRelatedProducts .productThumb a, #cartSideRecentlyViewed .productThumb a{margin:0;}
#cartRelatedProducts .title, #cartRelatedProducts .pricing, #cartSideRecentlyViewed .title, #cartSideRecentlyViewed .pricing{height: auto;}
#cartRelatedProducts .pricing, #cartSideRecentlyViewed .pricing{margin-top:5px;}

.relatedProducts{margin: 20px 0 0;}

.productListRow{padding:10px;}
.productListRow .image{float:left; width: 162px; height: 100px; overflow:hidden;}
.productListRow .title{display:block;}
.productListRow .description{float:left; width:75%; margin-top:1px;}
.productListRow .pricing{margin-top:10px;}
.productListRow .pricing, .productListRow .price{font-size:12px;}
.productListRow .price_sale, .productListRow .price{font-weight:bold;}
.productListRow .rating{margin:2px 0;}

.orderby_menu{float:right; font-size:11px;}
.orderby_menu select{font-size:11px; margin-left:5px;}
.orderby_select{float:left; margin-left: 5px;}
.sortbyMenu{position: relative;}
.sortbyMenu ul{display:none; position: absolute; z-index:50; width: 100%; border: 1px solid #999; background: #fff; list-style:none; padding: 0; margin: 0; border-radius: 5px;
box-shadow: 1px 2px 3px #e3e3e3;}
.sortbyOpen ul{display:block !important;}
.sortbyMenu li a{display:block; padding: 3px 8px;}
.sortbyDisplay{position: relative; display:block; padding: 3px 8px 3px 8px; 
border-radius: 3px; -webkit-border-radius:3px; -moz-border-radius:3px; margin: 0 0 1px;
text-align:center;}

.pages_select{margin:2px 0 0 3px; float:left;}
.pages_label, .orderby_label{text-transform:uppercase; float:left; padding-top:3px;}
.page{display:block; float:left; padding: 0 5px; cursor:pointer; margin-top:1px;}
.page:hover{text-decoration:underline;}
.page.active{font-weight:bold;}
.page.active:hover{text-decoration:none;}

/******************************/
/*Product*/
.cart_product .rating{float: right; position: relative; z-index:99; width:auto !important; height: auto !important; overflow:visible;}
.cart_product .rating .stars{float:left; cursor: pointer; position:relative; width:70px; height:13px; overflow:hidden;}
.cart_product .rating .full{position:absolute; left:0; background:url("../images/bigStars.png") left 0 no-repeat; height:13px;}
.cart_product .rating .empty{position:absolute; right:0; background:url("../images/bigStars.png") right -13px no-repeat; height:13px;}
.cart_product .rating .average{font-size:11px; text-transform: uppercase; margin-top:1px; text-align:right;float:left; margin-right: 5px; font-weight:bold;}
.cart_product .rating .rate{font-size:11px; margin-top:1px; text-align: center; cursor: pointer;}

.rate_form{position: absolute; z-index:500; top:-2px; right:-2px; display: none; border-left:0; border-top:0;
box-shadow: 3px 3px 5px -2px rgba(0,0,0,0.5); -moz-box-shadow: 3px 3px 5px -2px rgba(0,0,0,0.5); -webkit-box-shadow: 3px 3px 5px -2px rgba(0,0,0,0.5);}
.select_rating{position: relative; width:160px; float:right; background: #fff; border-bottom:0 !important;}
.select_rating h3{padding: 7px 10px; margin:0;}
.select_rating .close{position: absolute; top:7px; right:5px;}
.stars_select{float:left; cursor: pointer;}
.rating_form{width: 330px; padding: 10px 20px; margin-top: -1px; text-align:left; background: #fff;}
.rating_form .footer{margin: 10px -20px -10px; padding:10px 20px;}

.cart_product .images{position: relative;
float:left; width: 50%;}

.volPricing{padding: 5px; border:1px solid #eee; margin: 10px 0;}
.volPricingRow{line-height: normal; padding: 3px 0;}
.volPricingRow div{float:left; width: 50%;}
.volPricingHeader{border-bottom: 1px solid #eee;}
.volPricingRow .clear{float:none;}

.addCartQty{margin:8px;}

#main_image{position: relative;}
#image_locator{position: absolute; z-index:2; left:0; width:100%;}
#image_locator .ajax_med_load{width:100px; margin: 0 auto;}
.cart_product .images .main_image{position: relative; z-index:1; width:auto;}
.cart_product .images .main_image .caption{font-size: 11px; text-align:center;}
.cart_product .images .small_images{margin: 8px 0 0;}
.cart_product .images .small_images a{display:block; margin-right: 3px; float:left;}
.cart_product .images .small_images .image{position: relative; z-index:6; padding:0; text-align:center; cursor: pointer;
border:1px solid transparent;}
.activeImage{z-index:2;}
.cart_product .images .small_images .image img{width:35px; height: 35px; margin:0 auto;}
.small_images .image .zoom{position: absolute; z-index: 7; right: -75px; top: 2px;}
.small_images .image .zoom img{width:75px !important; height:75px !important; border:0 !important;}
.small_images .image .zoom{width:75px; height:75px;}
.zoom .caption{margin:0;padding:2px; font-size:11px; display:none;}

.accessories{}
.accessories .accessory{margin-top: 10px; font-size:12px;}
.accessory .title, .accessory .price{font-size:12px !important;}
.accessory .title{padding: 2px 0 2px !important;}
.accessory .checkbox{float:left; margin-right: 5px; width:5%;}
.accessory .image{float:left; margin-right: 5px; width: 30%;}
.accessory .info{float:left; width: 60%;}

.option_error{display:none; border: 1px solid red; color: red; background: url('../images/icon_error.gif') 3px 3px no-repeat #fbffcf; padding:5px; padding-left: 60px; margin: 0 0 5px; font-size: 11px; font-weight:bold; min-height: 28px;}
.option_error ul{margin:0; padding:0; list-style-type: square;}
.cart_product .options{margin-top: 5px;height:auto; position: relative; z-index:1;}
.cart_product .options .half{float: left; width: 50%; margin-top: 6px;}
.cart_product .options .left{float: left; margin-top: 2px;}
.cart_product .options .right{float: left; margin-left: 5px;}

.product_tabs{height: 24px; margin-top:15px;}
.product_tabs h3{margin:0; padding:4px 10px 0; font-size:13px;}
.product_tab{cursor:pointer; float: left; height:23px; border-bottom:0 !important; font-weight:normal; margin-left: 10px; text-align:center;}
.product_tabs .active{height:24px; background:#fff; font-weight:bold; cursor: default;}
.product_content{padding:10px; display:none;}

#content_specs{display:block;}
#content_specs .item{float:left; width:50%;}
#content_specs .itemInner{padding:5px;border-right:2px solid #fff;}
#content_specs .item .display{font-weight:bold; text-align:right; margin-right:20px;}
#content_specs .item .value ul{margin:0; padding:0; list-style:none;}
#content_specs .item .display, #content_specs .item .value{float: left; width:45%;}

.review_result{padding:10px;}
.review_name, .review_rating{float:left;}
.review_rating{margin-left:20px;}
.review_date{float:right;}
.review_comment{margin-top:5px;}

#content_photos .content{padding:10px 0px;}

/*#cartRelatedProducts h3, #cartSideRecentlyViewed h3{margin: 2px 0 4px; color: #03a001; font-size:13px;}*/
#cartOtherProductsFromBrand h3, #cartOtherProducts h3{margin: 15px 0 4px;}
#cartOtherProductsFromBrand, #cartOtherProducts{overflow:hidden;}
#cartSideRecentlyViewed{margin-top:20px;}

.cart_product .availability, .cart_product .stock, .cart_product .product_no, .cart_product .weight{display:none; margin: 2px 0;}
.cart_product .brand{margin-bottom:10px;}
.cart_product .specifics{margin: 8px 0; display:block; height:auto; position: relative; z-index:1;}
.cart_product .date{padding-top: 6px;}

.option_image{padding:1px; float:left; margin-right:3px; display:block;}
.option_image img{width:30px; height:30px;}
.activeValue{border:1px solid red; padding:0 !important;}

.lightbox{display:none; position: absolute; top:0; left: 0; width:100%; z-index:100; background: url('../images/lightbox_bg.png');}
#enlarge_image_locator{width:200px; margin:0 auto;}
#zoom_container{position: relative; width: 1000px; margin:20px auto;}
#zoom_body{position: relative;}
.zoom_control{position: relative; z-index:100; border:1px solid #cbcbcb; float:left; width:150px; margin-right:30px;}
.zoom_instructions{float:left; width:400px; text-align:center; color: #fff; font-size:12px; font-weight:bold; margin-top:50px;}
#zoom_gallery{position: absolute; top:0; left: 0; z-index:2; width: 90px; background: #fff; border: 1px solid #eee; border-right:0; margin-right: -1px;}
#zoom_gallery ul{list-style:none; padding:0; margin:0;}
#zoom_gallery li{padding: 3px 6px;}
#zoom_gallery a{border:1px solid #fff; display:block; width:75px;}
#zoom_gallery a:hover, #zoom_gallery .active{border: 1px solid #fb8800;}
#zoom_image{margin-left: 90px; position: relative; z-index:1; background: #fff; overflow:hidden;}
#zoom_inner{padding:5px; min-height: 320px; text-align:center;}
#zoomImageLoader{display:none;}
#enlarge_image_locator{padding: 10px; background: #fff;}

.zoom_contain{width: 400px; height: 400px; overflow:hidden;}
.zoom_image{width: 400px; height: 400px; border: 1px solid #eee; cursor: move; position: relative; overflow:hidden;}
.zoom_close{position: absolute; cursor: pointer; top: 5px; right: 5px; font-weight:bold; padding: 3px 5px; display: block; z-index:101;}
.zoom_info{position: relative; width:100%; margin:0; padding:0;}
.zoom_caption{margin-top:5px;}
.zoom_title{font-weight:bold; margin:5px 0; color: #fff; font-size:12px;}
.zoom_title span{font-weight:normal;}
/*.zoom_instructions{position: absolute; right:0; top: -5px; font-size: 9px; border: 1px solid #eee; background: #f8f8f8; padding:3px; border-top:0;}*/

.price_label, .price_amount{float:left;}
.price_amount{margin-left: 5px;}

.stars{position: relative;}
.stars_notrated{font-size: 10px; text-transform:uppercase; color: #cbcbcb; white-space:nowrap; font-weight:bold;}
.star, .star_half, .star_empty, .star_none, .star_select{float:left; width: 15px; height: 14px;}
.star{background: url('../images/star.gif') no-repeat center;}
.star_half{background: url('../images/star_half.gif') no-repeat center;}
.star_empty{background: url('../images/star_empty.gif') no-repeat center;}
.star_none{background: url('../images/star_none.gif') no-repeat center;}
.star_select{background: url('../images/star_select.gif') no-repeat center;}
.stars_select{float:left;}

/******************************/
/*Cart*/
#cartBasketContainer .header{position:relative; margin: 0px;}
#cartBasketContainer .header, .cart_bottom{}
.cart_bottom{padding-bottom: 10px;}
#cartBasketContainer .cartArrow{position: absolute; left: 0; top:0; background: url("../images/catArrow.gif") 5px center no-repeat #94a240; height: 18px; width:15px;}
#IE7 .cartArrow{left:-15px;}

.cart_top{display:table; width: 100%;}
.cart_header, .cart_total_label.total, .cart_total_price.total{}
.apply_discount{}
#cartBasket p{margin:0; padding: 5px;}
.cart_totals_numbers{float: right; width:35%;}
.cart_totals_numbers p{padding-right: 15px !important;}
.cart_header p{margin:0 !important; padding:0 5px 0 0 !important;}
.cart_row{position: relative; width:100%; padding:5px 0; z-index:1; overflow:visible;}
.cart_row p{margin: 2px 0 1px;}
.cart_row .title .lbl{text-decoration:underline; font-weight:bold; display:block; padding:0 0 2px;}
.cart_products, .cart_price, .cart_qty, .cart_totals{display:table-cell; vertical-align:middle;}
.cart_accessory_row{padding-top: 0px;}
.cart_accessory_row .cart_image{display:none;}
.cart_accessory_row .cart_info{padding-left:60px;}
.cart_accessory_row .title{font-weight:normal !important; font-size:11px;}
.cart_delete{position: absolute; right:-7px; top: 10px; z-index:2; width:15px;}
.cart_delete a{display:block; height:15px; width:15px; background: url("../images/cart_delete.gif") left top no-repeat;}
#IE7 .cart_delete{position:relative; float:left; top:0; left:-7px;}
.cart_products{float: left; width: 46%;}
.cart_accessory_row .cart_products{width: 44%; margin-left: 2%;}
#IE7 .cart_products{}
.cart_products p{}
.cart_products .cart_image, .cart_products .cart_info{float:left;}
.cart_products .cart_image{width: 50px; margin-left: 10px;}
.cart_products .cart_image img{border:1px solid #cbcbcb;}
.cart_products .cart_info{width: 70%; margin-left:5px;}
.cart_products .title{padding-left:5px; font-weight:bold;}
.cart_products .date{padding: 0 5px !important; font-size:11px;}
.cart_accessory .title{font-size:11px; font-weight:normal; padding-left: 10px;}
.cart_products ul{margin:0; padding:0 0 0 10px; list-style:none;}
.cart_accessory ul{padding-left: 15px;}
.cart_products li{display: inline; margin:0; padding-right:5px;}
.cart_price{float: left; width: 20%; text-align: right;}
.cart_price .price_reg{text-decoration:line-through; padding-top:5px;}
.cart_price p{padding-right:0 !important; padding-left:0 !important;}
.cart_qty{float: left; width: 10%; text-align: right;}
.cart_qty input{text-align:right; margin: -3px 0 0 0;}
.cart_totals{float: right; width: 20%; text-align: right;}
.cart_totals p{margin-right: 10px !important;}
.cart_total_qty{float: right; text-align: right; font-weight: bold; font-size: 13px; padding: 5px 0;}
.cart_total_label{text-align: right;}
.cart_total_label, .cart_total_price{float: left; width:50%; text-align: right;}
.cart_total_price p{padding-right: 15px !important;}
.cart_total_label.total, .cart_total_price.total{font-weight:bold; font-size: 14px;}
.cart_buttons{float: left; width: 60%; padding-left: 5px;}
.cart_products .options{font-size:11px;}

.rowBgAlt .cart_products_discount p{border-top: 1px solid #cbcbcb;}
.rowBg .cart_products_discount p{border-top: 1px solid #eee;}
.cart_products_discount{font-weight:bold;}
.cart_products_discount .cart_products{text-transform: uppercase; font-size:11px;}
.cart_products_discount .cart_totals{}


.cart_subtotal_title, .cart_tax_title, .cart_shipping_title, .cart_payfee_title, .cart_discount_title, .cart_total_title{float:left; width: 100px; text-align: right;}
.cart_subtotal_price, .cart_tax, .cart_shipping, .cart_payfee, .cart_discount, .cart_total{float:  right;}

.apply_discount{margin:6px 0 10px; padding:5px; border-top: 0;}

#cartBodyRelatedProducts h3, #cartBodyLastProductRelated h3, #cartBodyRecentlyViewed h3{margin: 20px 0 8px;}

/******************************/
/*Custom*/

#checkout h1, #customer_registration h1{margin: 0 0 5px; font-size: 26px; font-weight:normal;}
#checkout legend, #customer_registration legend{font-weight:bold; font-size:20px; padding: 8px 0; margin: 0 0 10px; display:block; width: 100%;}
#checkout dt, #customer_registration dt{clear:both; padding: 0 0 3px; font-size:12px;}
#checkout dd, #customer_registration dd{margin-bottom: 10px;}
#checkout dl, #customer_registration dl{clear:both;}
#checkout fieldset, #customer_registration fieldset{border:0; padding:10px 0 0; margin: 0 0 30px; width: 100%;}

.floatDls dl{float:left; clear:none !important; margin-right: 25px;}

.smField{width: 90px;}
.mdField{width: 140px;}
.lgField{width: 220px;}

.smField .textfield, .mdField .textfield, .lgField .textfield, .smField select, .mdField select, .lgField select{width: 99%;}

#clickZoom{margin: 5px 0; font-size:10px; padding: 3px 0 3px 15px; display:block; background: url("images/zoom.gif") left center no-repeat;}

#cartAccountSideMenu ul{list-style: square; margin: 0 0 0 20px; padding:0;}

.leftcol .productThumb{float:none; width: auto;}
.moduleContain{margin: 0 0 20px;}

#requiredText{font-size:11px;}
.required{color: red;}

.frmrow{padding:0px 0 14px;}
.frmrow label, .frmfield label{display:block; line-height: 100%; padding: 0 0 4px;}
.frmfield input[type="text"], .frmfield input[type="password"], .frmfield select, .frmfield textarea{width:100%;
box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box;}
.frmfields2 .frmfield{width: 48%; float:left}
.frmfields2 .frmfield:nth-of-type(2){float:right;}
.frmfields3 .frmfield{width: 32%; float:left}
.frmfields3 .frmfield:nth-of-type(2){margin: 0 0 0 2%;}
.frmfields3 .frmfield:nth-of-type(3){float:right;}

.address2 label{display:none;}

/*3.1.8*/
.center_contain{position: fixed; z-index: 5000; width: 100%; top: 0; left: 0;
background: rgba(0,0,0,0.4); height:100%;}
.center_contain_in{position: fixed; top:100px; left:0; width: 100%;}
.center_contain_box{background: #fff; padding: 20px; margin: 0 auto; position: relative;
box-shadow:0 0 8px rgba(0,0,0,0.4);
-webkit-box-shadow:0 0 8px rgba(0,0,0,0.4);
-moz-box-shadow:0 0 8px rgba(0,0,0,0.4);}
.center_close{position: absolute; right:10px; top:10px;}
#addedtocart .center_contain_box{width:350px;}

.td{float:left; padding: 6px 0;
box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box;}
.resultsHeader .td{font-weight: bold;}
.address_row, .order_row{border-bottom: 1px solid #eee; position:relative;}

/*.address_formleft{float:right; width: 40%;}
.address_formright{float:left; width: 56%;}*/
.address_email{width: 60% !important;}
.address_phone{width: 35% !important;}
.address_isbilling, .address_isshipping{position: relative;}
.address_isbilling .inputfield, .address_isshipping .inputfield{position: absolute;
left:0; top:0;}
.address_isbilling label, .address_isshipping label{display:block; padding-left: 16px;
line-height: 115%;}
.address_row .label{width:18%;}
.address_row .label p{margin: 10px 0 0;}
.address_row .label .cartRegButton{padding: 5px !important; font-size:11px;}
.address_row .address{width:38%;}
.address_row .address p{margin: 0 0 10px;}
.address_row .address > p:nth-of-type(2){font-size:11px;}
.address_row .defaultshipping, .address_row .defaultbilling{text-align:center; width: 18%;}
.address_row .status{width:8%; text-align:center;}
.address_row .ajax_load{position: absolute; right:0; bottom:10px; width: 44%;}

#add_address_popup .center_contain_box{width:700px;}
#add_address_popup h2{margin-top:0;}
#add_address_popup .ajax_error, #add_address_popup .ajax_load,
#add_address_body .ajax_error, #add_address_body .ajax_load{margin: 0 0 16px;}

#account_addresses .inactive{opacity:0.7; filter:alpha(opacity=70);}

#account_lobby .section{margin: 0 0 40px;}
#account_lobby h4{font-size:15px;}
#account_lobby .resultsmessage{display:none;}
#account_lobby .address{float:left; width: 33%; margin: 0 0 25px;}
#account_lobby .address a{text-decoration:underline;}
#account_lobby .address p{margin: 5px 0 10px;}
#account_lobby .address > p:nth-of-type(2){font-size:11px;}

.billaddresses_radios .address, 
.shipaddresses_radios .address{cursor:pointer; position: relative; padding: 10px 10px 10px 42px; border-top:1px solid #eee;}
.billaddresses_radios .address input[type="radio"], 
.shipaddresses_radios .address input[type="radio"]{position:absolute; left: 5px; top:10px; display:none;}
.billaddresses_radios .address .checkmark, 
.shipaddresses_radios .address .checkmark{position: absolute; left:10px; top:10px;}
.billing_select label, .shipping_select label{display:block; padding: 0 0 5px;}
.billaddresses_radios .selected, .shipaddresses_radios .selected{background: rgba(0,0,0,0.05);}
.billaddresses_radios .selected .checkmark div, .shipaddresses_radios .selected .checkmark div{display:block;}

.inlineButton{text-decoration:underline; font-style: italic;}

#step2{padding: 0 10px;}
#checkout_addressbook{padding: 10px; background: #eee; margin: 10px 0;}
#checkout_addressbook h3{margin: 0 0 10px;}
#checkout_addressbook #add_address_body{margin:15px 0 0; border-top: 2px solid #ccc;padding: 15px;
background: rgba(255, 255, 255, 0.5);}

#addAddressAjaxForm{position: relative;}

.account_type_section h4{cursor:pointer;}
#checkout_singlepage .section hdr{margin:-10px -10px 0; display:block; display:block; border-top: 1px solid #ccc; font-size:15px;
padding:10px; cursor: pointer; background: #eee;}
#checkout_singlepage .section.open hdr{font-weight:bold; background: transparent;}
#checkout_singlepage .section hdr .ajax_load{float:right;}
/*#checkout_singlepage .section hdr plus{float:right; font-size:14px;}
#checkout_singlepage .section hdr plus:before{content: "+";}
#checkout_singlepage .section.open plus:before{content:"-";}*/
#checkout_singlepage .section{border-bottom:1px solid #ccc; padding:10px 10px 0;}
#checkout_singlepage .section .content{display:none; padding-bottom:10px;}
#checkout_singlepage .section.open .content{display:block;}
#csp_shipping, #csp_billing{float:left; width: 55%;}
#csp_shipoptions, #csp_payoptions{float:right; width: 40%;}
#csp_shipping #checkout_shipping, #csp_billing #checkout_billing{float:none !important; width:auto !important;}
#csp_accountselection_summary .smallButton{float:right;}
#csp_shipping #checkout_shipping h3, #csp_billing #checkout_billing h3, 
#csp_shipping #checkout_shipping #sameasbill{display:none;}
.shipping_select h3, .billing_select h3{display:block !important; margin-left:8px;}

#CheckoutSelectShipOption_Button{clear:both; float:right; margin: 12px 0 0;}

.shipopt_selection, .payopt_selection{ position: relative; padding:15px 42px;}
.shipopt_selection, .payopt_selection{cursor:pointer; padding-right:15px !important;}
#account_type_sections > .account_type_section:nth-child(2){border:1px solid #ccc; border-top:0; border-bottom:0;}
.shipopt_selection.selected, .payopt_selection.selected{background: #f9f9f9;}
.account_type_section input[type='radio'], .shipopt_selection input[type="radio"],
.payopt_selection input[type="radio"]{position: absolute; left:10px; top:15px; display:none;}
.account_type_section .checkmark, .shipopt_selection .checkmark,
.payopt_selection .checkmark{position: absolute; left:12px; top:12px;}
.account_type_section.selected .checkmark div, .shipopt_selection.selected .checkmark div,
.payopt_selection.selected .checkmark div{display:block;}
.shipment_selections > .shipopt_selection:nth-child(1n + 2),
#payopt_selections > .payopt_selection:nth-child(1n + 2){border-top:1px solid #ccc;}
.shipopt_selection label{float:left; cursor:pointer;}
.shipopt_selection price{float:right; cursor:pointer;}

.smallButton{text-decoration: underline;}

#csp_shipping_summary .address, #csp_billing_summary .address{float:left; width:40%;}
#csp_shipping_summary .options, #csp_billing_summary .options{float:left; width:40%;}
#csp_shipping_summary shipment{margin: 0 0 10px;}
#csp_shipping_summary shipment ship_no{display:inline-block; text-decoration: underline; margin-right: 10px;}
#csp_shipping_summary shipment method{display:inline-block;}
#csp_shipping_summary .edit, #csp_billing_summary .edit{float:right;}

.ajax_error{padding-left:65px;}
.ajax_error ul{margin-left:0;}

#csp_billing #sameasship{margin: 0 0 10px;}

#add_address_body .cancel{margin-right: 30px;}
#sameasship{margin-top: 15px;}
#confirm_shipping h3, #confirm_billing h3{text-decoration: underline;}
#order_display_shipping, #order_display_billing{line-height:135%;}
#checkout_comments p{margin-bottom:5px;}
#checkout_singlepage h1{font-size:18px; font-weight:bold;}
h2{font-size:18px;}

#account_type_sections{width:100%;}
.account_type_section{position: relative;}
#account_type_sections > .account_type_section:nth-child(2){border:1px solid #ccc; border-top:0; border-bottom:0;}
.account_type_section h4{margin: 0 0 6px; font-size: 13px;}
.account_type_section input[type='radio']{position: absolute; left:10px; top:15px; display:none;}
.account_type_section .checkmark{position: absolute; left:12px; top:12px;}
.account_type_section.selected .checkmark div{display:block;}
.account_type_section .checkout_continue{margin-top:10px !important;}

.address_isresidential .frmfield{
	float:none; width:100%;padding-left:0;
	label{position:relative; width:100%;  text-align: left;}
}
.address_isbilling .inputfield, .address_isshipping .inputfield{position:relative;}