/* Welcome to Compass.
 * In this file you should write your main styles. (or centralize your imports)
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/screen.css" media="screen, projection" rel="stylesheet" type="text/css" /> */

@import "compass/css3";
@import "compass/utilities";
@import "_reset";
@import "_icons";
@import "_mixins";
@import "_default";

$a_hover: #ec1c24;
%font{
	//font-family: 'Maven Pro';
	font-family: 'Raleway';
	@include fontsize(1.3);
}
body{background: #a06b34;
 	@extend %font;
	@include inputs{
		@extend %font; @include padding(.5); margin:0; outline:0; focus:0;
		border:1px solid #d0cece; @include radius(.4);
	}
	select{@include padding-r(1.5);
		@include appearance(menulist);
		//&:after{float:right; @include icon('arrowdown');}
	}
	%button_static{
		background: $a_hover; cursor:pointer; outline:0; focus:0;
			//@include background-image(linear-gradient(top, #ee282f, #e11b22));
			color: #fff; text-transform:uppercase; @include fontsize(1.5);
		@include inline-block; border: 0; //border:1px solid #000;
		@include padding(1, 1.2); margin:0;
		@include radius(0.5);}
	@include buttons{
		@extend %button_static;
		&:hover{background: #454444; color: #fff;
			//@include background-image(linear-gradient(top, #666565, #373636));
		}
	}
	p{@include margin(0,0,1);}
}


%ahover{&:hover{color: $a_hover;}}
a{text-decoration:none; color: #000;
	@extend %ahover;
}
.container{max-width:1153px; margin: 0 auto;}

.qty_display{display:none !important;}

$header_height: 193px;
#header{@include background-image(linear-gradient(left, #ac884d, #ac884d 50%, #d9c495 51%, #d9c495));
	position: relative;
	&:before{height:$header_height; content: " ";
	width: 100%; position: absolute; bottom:0; left:0; z-index:1; background: url('../images/headerbg.jpg') center bottom no-repeat; display:block;}
	.container{position:relative; z-index:2;}
	position: relative; height:$header_height;

	#top-menu{
		background: #cf0008;
		position: relative;
		z-index: 2;
		.container{
			&, ul{
				@extend %clearfix;
			}
			ul{float:right;    display: flex;
				flex-direction: row;
				list-style:none; padding: 8px 0; margin:0;
				li{float:left; padding: 0 10px;
					display: flex;
					flex-direction: column;
					align-self: center;

					&, a{color: #fff;}
					#shoppingcart a{
						svg{display:inline-block; width: 26px; height: 21px; fill: currentColor;position: relative;
							top: 1px;}
						span{display:inline-block; background: #fff; color: #cf0008; border-radius: 50%;
							padding: 1px 4px; position:relative; top:-5px;}
					}
				}
			}
		}
	}

	#logo-search{@extend %clearfix; width:100%;
		display:flex; flex-direction: row; align-items: center;
		@include bp_minmax(501px, 1172px){
			padding-left: 10px; padding-right: 10px;
		}

		#logo{
			a{padding: 8px 0;
				img{max-width: 575px; width:100%; height:auto; display:block;}
			}

			@include bp_min(501px){
				float:left; display:flex; flex-direction: column; width: 75%;
			}
		}
		.modulesection_header{
			display:none;
			@include bp_min(501px) {
				display:block;
				float: right;
				width: 25%;
				display: flex;
				flex-direction: column;
			}

			#searchcontain{
				@include padding(.4); background:#fff; border-radius:5px; opacity:0.9;

				input{float:left; border:0; @include rem('width', 18.5);}
				a{
					display:block; float:right; padding: 3px;
					//&:before{@include icon('search'); @include fontsize(2.5); color: #ed1c24;}
					//&:hover:before{color: #666;}
					color: #cf0008;
					&:hover{color: #666;}

					svg{fill:currentColor; width: 16px; height: 16px;}
				}
			}
		}
	}
	ul#mobilemenu{
		background: rgba(255, 255, 255, 0.6); @include bottom-radius(.5); @include padding(2.5, 1.5, 1);
		position:absolute; top:0; right:0; list-style:none;
		li{
			float:left; @include padding-l(1.2); @include margin-l(1); position:relative;
			a{text-transform:uppercase; @include fontsize(1.2);}
			&:before{
				content:"|"; position:absolute; left:0; top:0;
			}
			&:first-child{
				padding-left:0; margin-left:0;
				&:before{display:none;}
			}
		}
		#shoppingcart a{
			&:after{@include icon('cart'); @include fontsize(3);float: right;
			@include padding-l(.4); @include margin-t(-1);}
		}
	}
}

#account_login{
	padding: 10px 20px;


}

#menu{
	$menu_height: 4.4;
	background: #000;
	ul{
		display:table; table-layout: fixed;
		list-style:none;
		li{display:table-cell; width:1%;
			a{
				@include fontsize(1.6); color: #fff; text-align:center; display:block;
				@include rem('height', $menu_height); @include rem('line-height', $menu_height);
				&:hover, &.active{background: #ed1c24;}
			}
		}
	}
    #shopmenu ul{display:none;}
}

#body{
	//background: url("../images/bodybg.gif") center bottom repeat-x #a06b34;
	background: #d59859;
	@include padding(1.5, 0);
	.topshadow{@include lifted-corners('top', -5px 0 10px rgba(0, 0, 0, 0.7), -4deg, 5px 0 10px rgba(0, 0, 0, 0.7), 4deg, .5, .4, 10px, 30%);}
	.bottomshadow{@include lifted-corners('bottom', -5px 0px 10px rgba(0, 0, 0, 0.7), 4deg, 6px 0px 10px rgba(0, 0, 0, 0.7), -4deg, .5, .4, 10px, 30%);}
	.topshadow, .bottomshadow{
		&:before, &:after{@include rem('max-height', 10);}
	}
	.container{
		.bg{background: #fff; @include padding(1, 0); position:relative; z-index:2; @extend %clearfix;}
		#cartBreadcrumbs{border-bottom:5px solid #d9e2ba; @include rem('border-width', .5);
			@include margin-t(-1); @extend %clearfix;
			@include padding(2, 2, 2, 4); @include margin-b(1); background:url("../images/bullet.gif") 10px center no-repeat;
			ul{@extend %clearfix; float:left;}
			li{float:left; @include padding(0, .5);}
		}

		#leftcol{float:left; width:25%;
			#nav{
				ul ul{display:none;}
				.activeLi > ul{display:block;}
				ul{list-style:none;
					li{
						ul{
							@include margin(.5,0,0,4);
							li{@include padding(.5, 0);}
							a{
								color: #804620; @include fontsize(1.6);
								&:hover{color: $a_hover;}
								&.active, &.current{color:#000;}
							}
						}
					}
				}
				& > ul{
					& > li > a{
						$a_root_height: 3.6;
						@include fontsize(1.8); display:block;
						@include padding(1, 2); 
						//@include rem('min-height', $a_root_height); 
						//@include rem('line-height', $a_root_height);
						position:relative;
						&.active, &.current{
							background: #e6efd9;
							&:before{
								position:absolute; bottom:0; top:0; @include rem('left', -.1);
								background: #ed1c24; @include rem('width', .4); 
								border-left:1px solid #e6efd9; content:" ";
							}
						}
							
					}
				}
			}
		}
		#rightcol{float:right; width: 72%; @include padding-t(.5); margin-right:1%;}
	}
}

#footer{position:relative;
	background:#000000; color: #fff;
	&:after{
		position:absolute; bottom:0; top:0; right:0; left:0; z-index:1;
		background: url("../images/bodybg.gif") center bottom repeat-x; background-size:cover;
		width: 100%; content:" ";
		opacity: 0.3;
	}
	.container{@extend %clearfix; position:relative; z-index:2;
		@include padding(3, 2);
		#nl{
			@extend %clearfix; @include padding-b(1.5); float:left;
			label{ color: #fff; @include fontsize(1.8); @extend %clearfix;
				display:block; @include padding-b(1);}
			input{@include rem('height', 3.2); line-height:normal;}
			input[type="text"]{clear:both; float:left; border:0; @include rem('width', 24); @include radius(0);}
			input[type="submit"]{float:left; @include radius(0); border:0; @include padding(0, .8);
				@include fontsize(1.4);}
            /*.fsBody{
              .fsForm{background: transparent; margin:0; border:0; @extend %clearfix;
                .fsPage{float:left; min-width:200px !important; max-width:200px;
                  .fsRowBody{padding:0;}
                  label{display:none;}
                  .fsFieldRow input[type=email], .fsFieldRow input[type=number], .fsFieldRow input[type=tel], .fsFieldRow input[type=text], .fsForm textarea{
                    @include border-radius(0); border:0; background: #fff; max-width:100%;
                  }
                }
                .fsSubmit{clear:none; float:left;
                  padding:0; margin:0; background: transparent;
                }
              }
              form{
                div{padding:0 !important; font-weight:400 !important; text-align:left !important;
                  font-size:12px !important;
                  p{color:#fff;}
                }
              }
            }*/
            & > div > a{display:none;}
		}
		ul{
			float:right; list-style:none; @include margin(1,0);
			li{
				float:left; @include margin-l(1.5);
				a{
					color:#fff; @include fontsize(1.3); @extend %ahover;
				}
			}
		}
		#socials{
			clear:right; float:right;
			a{@include inline-block;
				@include radius(.3); @include margin-r(.5);
				background: #fff; color: #000;
				@include rem('width', 2.6); @include rem('height', 2.6);
				@include rem('line-height', 3.3); text-align:center;
				@include fontsize(2.2);
				&:hover{background: $a_hover;}
			}
			#f_facebook{
				$fb_size: 2.5;
				position:relative;
				&:before{@include icon('facebook'); position:absolute; bottom:0; @include rem('right', 0);
				@include fontsize($fb_size); @include rem('line-height', 1.8); @include rem('letter-spacing', -0.2);}
			}
			#f_twitter{
				&:before{@include icon('twitter');}
			}
		}
		.seal{float:right;}
	}
}

#copyright{@include fontsize(1); color: #fff; @include padding(1);
	a{color:#fff;@extend %ahover;}
}

.category_header, .category_products_message{display:none;}
#sportsme_category{@extend %clearfix; 
	@include margin(0, -1, 1.5, 0);
	.areaA{float:right;}
}
#categoryProducts {
	&, #productThumbs{@extend %clearfix;}
	@include bp_min(768px){
		#category-products-top{
			.pages_menu{position: absolute;
				right: 1%;
				top: 17px;}
		}
	}
	#category-products-bottom{
		.pages_menu{float:right;}
	}
}

.productThumbs, #productThumbs{
	@extend %clearfix;
	.productThumb{
		float:left; width: 33%; @include margin(0,0,2); @include padding(0,1); position: relative; text-align:center;
		a{display:block; @include padding(1); margin:0; background:#fff; width:100%;
			&:hover{@include box-shadow(0 0 10px rgba(#000, 0.2));
				.detailview{@include opacity(1);}}
		}

		label{display:block; line-height:145%; text-align:center; @include margin(0,0,.4);
			text-transform: uppercase; @include fontsize(1.3); color: #000;}
		.image{display:block;text-align:center; border:1px solid #ebebeb; overflow:hidden; @include margin(0,0,.8);
          img{display:block !important; max-width:100%; height:auto; margin: 0 auto;}
        }
		.imageNone{margin:0 auto; text-align:center; border:0;}
		.pricing{@include margin-b(1);}
		.price, .price_reg, .price_sale{text-align:center; @include fontsize(1.5);}
		.price_reg{text-decoration:line-through; display:inline-block; @include margin-r(1);}
		.price_sale, .price{color: $a_hover;}
		.detailview{margin: 0 auto; @extend %button_static; @include fontsize(1.3, !important);
			@include opacity(0);}
	}
	& > .productThumb:nth-child(3n + 4){clear:both;}
}

.perpage_menu{display:none;}
.page_first, .page_last, .page_prev, .page_next{
	display:block; float:left;  cursor:pointer; font-size:0;
	&:hover{color:#ec1c24;}
    &:before{ @include fontsize(1.4);}
}
.page_first:before{@include icon('page_first');}
.page_last:before{@include icon('page_last');}
.page_prev:before{@include icon('page_prev');}
.page_next:before{@include icon('page_next');}

.category_description{
	h1{color: $a_hover; font-weight:normal; @include margin(0,0,1.5); text-transform:uppercase;}
}
.categoryTextThumb{float:left; width: 33%; @include margin(0,0,1);
	a{@include fontsize(1.4);}
}

.cart_product{ 
	@extend %clearfix;
	.rating, .brand{display:none;}
	h1{@include fontsize(2.4); @include margin(0,0,1.2); font-weight:normal;}
	.subtitle{margin:0;padding:0;}
	#main_image > a{border:1px solid #eaeaea;}
	.description{
		line-height:140%; float:right; width: 48%; margin-right: 2%;
		.text{@include fontsize(1.3);line-height: 160%;}
		p{margin: 0 0 10px;}
        ul{list-style: square; @include margin-l(2);}
	}

	.pricing{text-align:left; @include fontsize(1.8); @include margin(2, 0);}
	.price_reg{@include margin-r(1.5); text-decoration: line-through;}
	.price_sale, .price{color: $a_hover;}
	.price_reg, .price_sale, .price{@include inline-block; }

    .options{
      select{width:96%;}
    }

	.order_buttons, #order_outofstock{margin-top:8px;}
	#addtocart_button{//@include fontsize(2);
		clear:both; @include margin(1, 2, 0, 0);
	}
	#addtowishlist_button{
		@include inline-block; font-weight:bold;
		color: #666; //#df8011;
		//@include fontsize(1.5);
		text-transform: uppercase;
		&:hover{color: $a_hover;}
		/*&:before{@include icon('heart'); float:left; @include fontsize(2);
			@include margin(-.2, .2, 0, 0);
		}*/
	}
	#order_outofstock{}

	.socials{margin: 20px 0 0;}
}


.relatedProducts{
	@include margin(1.5, 0, 0);
	h3{@include fontsize(1.8); display:block; @include padding(0,0,.6); margin-bottom:0;
		border-bottom:5px solid #d9e2ba; font-weight:normal;}

	.relatedproducts_slider{
		position:relative; @include padding(0, 4);
		a{display:block; margin:0 auto;}
		.flex-prev, .flex-next{position: absolute; top:50%; @include margin-t(-4.5);
			font-size:0; color: #9f6a34;
			&:before{@include fontsize(3);}
			&:hover{color: $a_hover;}
		}
		.flex-prev{left:0;
			&:before{@include icon('arrowleft');}
		}
		.flex-next{right:0;
			&:before{@include icon('arrowright');}
		}
	}
	.flex-viewport{@include padding(1, 0);
		li{}
	}
	.productThumb{float:none; width:auto; margin:0 auto; text-align:center;
		label{@extend %text-truncate; display:block; @include margin(.5, 0); cursor:pointer;}
	}
}

%checkout_header{font-weight:normal; @include fontsize(2);}
#cartBasketContainer{@include margin(0,0,3);}
#cartBasketContainer .header h2, .moduleContain h3{ @include margin(0,0,.8);}
.checkout_header, .account_type_header,
#cartBasketContainer .header h2, .moduleContain h3, #checkout_payment h3{@extend %checkout_header;}


#cart_form{
	.qty_display{display:none !important;}
	.free_product, .hide-qty-field{
		.qty_display{display:block !important;}
	}
}
.cart_header{
	border-bottom:1px solid #eee;
	.cart_header{border:0;}
}
.cart_row{@extend %clearfix;
	.mobile{display:none;}
	.cart_qty{
		//span{display:none;}
		&.max-qty-1{
			.qty_display{display:block !important;}
		}
	}
}
.cart_bottom{border-top:1px solid #eee; @include padding-b(1); @extend %clearfix;}
#apply_discount_button{@include fontsize(1.1); @include margin-l(1);}
.cart_totals_numbers{@include margin-t(.5);}

#cartButtons{@include margin-t(2); @extend %clearfix;
	ul{float:left; @extend %clearfix; @include margin-t(.8);
		li{@include margin-r(1.5); float:left;}
	}
	#checkoutButton{float:right;}
}

.account_type_row{@include fontsize(1.3);}
#account_type_2 .forgot_password{@include fontsize(1.1); @include padding-t(.5);}

#checkoutSteps{@include margin-b(2);
	ul{list-style:none; margin:0; padding:0; width: 100%;
		@extend %clearfix;}
	li{ margin: 0; width:25%; float:left; display:block; position:relative;
		text-align:center;
		.number{display:none;}
		.title{@extend %checkout_header;}
		/*&:nth-of-type(1){width:11%; text-align:left;}
		&:last-of-type{width:11%; text-align:right;}*/

		a{@include inline-block; @include padding(0, .2, .5); border-bottom:4px solid transparent;}
		a:hover, &.active a{color: #ed1c24; border-color: #ed1c24;}
	}
}
.checkout_button:link, .checkout_button:visited{
cursor: pointer;
display: block;
float: right; @include fontsize(1.4); @include margin-r(1.7);
font-weight: 700;
text-align: center;
z-index: 200;}


#checkout_billing, #checkout_shipping{/*@include rem('width', 42);*/
	.addresslabel, .firstname, .lastname{width:70%;}
	.cityzip{
		.frmfield{
			&:nth-child(1){width: 65%;}
			&:nth-child(2){float:right; width: 35%;
				@include padding-l(5.5);
				label{@include rem('width', 5);}
			}
		}
	}
	.countrystate, .phone{
		.frmfield{
			&:nth-child(1){width: 58%;}
			&:nth-child(2){float:right; width: 41%;
				@include padding-l(6);
				label{@include rem('width', 5.5);}
			}
		}
	}
}
#checkout_shipping{@include margin-t(1);}
#addShipAddressLink, #addBillAddressLink{margin-right: 15px; font-weight:bold; text-transform:uppercase; display:block;}
#addShipAddressLink:hover, #addBillAddressLink:hover{}

#confirm_billing, #confirm_shipping{width:50%; float: left; @include margin(0,0,2.5); line-height:140%;}
#confirm_billing h3, #confirm_shipping h3{margin:0 0 4px; font-weight:normal; text-decoration:underline;}

.frmrow{@extend %clearfix;

}
.frmfield{position:relative; @include padding-l(12.5);
	label{position: absolute; @include rem(top, .6); left:0; @include rem('width', 12); text-align:right;}
	input, select{}
}

#contactLeft{line-height:140%;
h1{margin-top:0;}}

.sortbyDisplay{@include rem('width', 12);}

#featuredProducts{
	h3{color:#ed1a29; @include fontsize(2);}
	.productThumbs{@include margin-t(1);}
}

#checkout_addressbook{@extend %clearfix;}

#account_type_sections{@extend %clearfix; @include margin-t(3);
	> div:first-of-type{float:left; width:48%;}
	> div:nth-of-type(2), > div:nth-of-type(3){float:right; width: 48%;}
	> div:nth-of-type(3){clear:right;}
	.account_type_section{ @include margin-b(3);
		.checkmark{display:none;}
		h4{@extend %checkout_header;}
	}
	#account_type_1{
		.frmfields2 .frmfield{float:none; clear:both; width:100%;
			&:nth-of-type(2){margin-top:15px;}
		}
	}
	#account_type_2{
		.frmrow{@extend %clearfix;
			label{float:left; @include padding-t(.7);}
			.fld{float:left; @include margin-l(1);}
		}
		p{@include margin(.7, 0, 0); @include fontsize(1.1);}
		.forgot_password{a{text-transform: lowercase; text-decoration: underline;}}
	}

}
#account_type_sections>.account_type_section:nth-child(2){border:0 !important;}

.address_formleft{@include padding-b(1); border-bottom:1px solid #eee;
@include margin-b(2);
	.address_isshipping, .address_isbilling{
		@include padding-l(2);
		label{position:relative; width:auto; padding-left:0; text-align:left;}
	}
}
#addAddressAjaxForm{@extend %clearfix;}
#addAddressAjaxForm .buttons{float:right;}

#checkout_payment{@include margin-t(2);}
#checkoutAffiliateCode{@include margin-b(3);}

#cartAccountSideMenu{@include padding(0, 2, 2);}

#change_pass{
	.frmfields2{
		.frmfield:nth-of-type(2){padding-left:0;
			.frmfield{width:100%;}
		}
	}
}

#create_form{
  .width100P, .width75P{
    input{width:100%;}
  }
  #left{float:left; width: 46%;}
  #right{float:right; width: 46%;}
}

#offline{@include margin-t(5); padding-top:217px;
  background: url("../images/offlinebg.jpg") center top repeat-x;
  position:relative;
  .logo{position: absolute; width:100%; z-index:2; text-align:center; top:105px; left:0;}
  .banner{position:relative; z-index:1; text-align:center; @include padding(4, 0, 2);
    background: #fff; border:1px solid #a06b34; border-left:0; border-right:0;
    @include boxshadow(0px 10px 15px rgba(0,0,0,0.2));

    h1{text-transform: uppercase; font-weight:400; @include margin(0,0,1);
    @include fontsize(3);
    }
    h3{@include fontsize(2); text-transform: uppercase; font-weight:400; @include margin(0,0,2);}
    h2{@include fontsize(3.8); color: #e11b22; font-weight:400; @include margin(0,0,2);}
    p{@include fontsize(1.6); line-height:140%; margin:0;}
  }
}

.imageNone img{max-width:100%;}
#mobileswipe{display:none;}
.waiting_for, #productunavailable{display:none;}
.option{
  &.waiting{@include opacity(.4);
    .option_selection, .option_checkboxes, .option_images, .option_radiobuttons{pointer-events: none;}
    .option_selection{ option{display:none;} }
  }
}

#account_type_sections{
	> div{
		&:first-of-type{}
	}
}

.shipment_selections{clear:both;}
.shipment_includes{
	label{display:block; margin: 0 0 10px;}
	ul{background: #eee; border:1px solid #ccc;
		li{float:none;
			@extend %clearfix; padding: 10px; border-top:1px solid #ccc;
			> div{
				float:right; width: 50%;
				&:first-of-type{float:left;}
				&.split{float:right; text-align:right;}
			}

			&:first-of-type{border-top:0;}
		}
	}
}
.shipopt_selection label{float:left; cursor:pointer;}
.shipopt_selection .price{float:right; cursor:pointer;}

#cartSearchResults h2{display:none;}

.main_image{text-align:center;}

#account_type_sections .account_type_section h4{font-size:16px;}


.productListRow{padding:10px;}
#my_wishlist{
	.productListRow{
		.subProductList{padding-top:15px;
			.image{width:10%;}
		}
		.info{margin-top:8px;}
	}
}
.productListRow .image{float:left; width:25%; overflow:hidden; padding: 0 5px 0 0;
	img{@include maxwidth100;}
}
.productListRow .title{display:block;}
.productListRow .description{float:left; width:75%; margin-top:1px;}
.productListRow .pricing{margin-top:10px;}
.productListRow .pricing, .productListRow .price{@include fontsize(1.2);}
.productListRow .price_sale, .productListRow .price{font-weight:bold;}
.productListRow .rating{margin:2px 0;}

.ca-warning{margin: 30px auto 20px;
	clear: both;
	width: 72%;
	min-width: 300px;

	img{@include maxwidth100;}
}

@import "_mobile";

#main_image > a{width: 96.5% !important;}

.responsive-iframe{
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;

	iframe{width: 100% !important; height: 100% !important;
		position: absolute;
		top: 0;
		left: 0;
		border: 0;}
}

.productListRow .image{height:auto;}