@charset "UTF-8";

@font-face {
  font-family: "sportsmen";
  src:url("fonts/sportsmen.eot");
  src:url("fonts/sportsmen.eot?#iefix") format("embedded-opentype"),
  url("fonts/sportsmen.woff") format("woff"),
  url("fonts/sportsmen.ttf") format("truetype"),
  url("fonts/sportsmen.svg#sportsmen") format("svg");
  font-weight: normal;
  font-style: normal;
}

%fonticon{
  font-family: "sportsmen" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before, 
[class^="icon-"]:before,
[class*=" icon-"]:before {
    @extend %fonticon;
}

@mixin icon($icon){
    @extend %fonticon;
    $font: "";
    
    @if $icon == "cart"{$font: "s";}
    @else if $icon == "search"{$font:"m";}
    @else if $icon == "page_prev"{$font: "p";}
    @else if $icon == "page_next"{$font: "n";}
    @else if $icon == "page_first"{$font: "f";}
    @else if $icon == "page_last"{$font: "e";}
    @else if $icon == "facebook"{$font:"c";}
    @else if $icon == "twitter"{$font:"a";}
    @else if $icon == "heart"{$font:"h";}
    @else if $icon == "up"{$font:"u";}
    @else if $icon == "left"{$font:"l";}
    @else if $icon == "down"{$font:"d";}
    @else if $icon == "right"{$font:"r";}
    @else if $icon == "user"{$font:"g";}
    @else if $icon == "menu"{$font:"b";}

    content: $font;
}
